<template>
  <v-container class="general">
    <PageTitle :title="$t('profile_page.title')" />
    <v-card class="mb-4 pt-5 px-4 pb-4 border-radius">
      <v-flex class="xs8">
        <v-layout class="row wrap">
          <v-flex class="xs6 mb-4 pr-2">
            <v-text-field
              v-model="model.first_name"
              :error-messages="errors.collect('first_name')"
              v-validate.disable="'required'"
              data-vv-name="first_name"
              :data-vv-as="$tc('first-name', 2)"
              :label="$tc('first-name', 1)"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs6 mb-4 pl-2">
            <v-text-field
              v-model="model.last_name"
              :error-messages="errors.collect('last_name')"
              v-validate.disable="'required'"
              data-vv-name="last_name"
              :data-vv-as="$tc('last-name', 2)"
              :label="$tc('last-name', 1)"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs12 mb-4">
            <v-text-field
              v-model="model.email"
              :error-messages="errors.collect('email')"
              v-validate.disable="'required|email'"
              data-vv-name="email"
              :data-vv-as="$tc('email', 2)"
              :label="$tc('email', 1)"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs6 pr-2">
            <v-combobox
              v-model="model.phone_code"
              :items="dialCodes"
              v-validate.disable="model.phone ? 'required' : ''"
              :error-messages="errors.collect('phone_code')"
              :data-vv-name="'phone_code'"
              :data-vv-as="$tc('phone-code', 2)"
              :search-input.sync="searchInput"
              :label="$tc('phone-code', 1)"
              persistent-hint
              maxlength="3"
            >
              <template slot="selection" slot-scope="data">
                <div :style="{ fontSize: '14px' }">
                  <span
                    v-if="iso2(data.item)"
                    :class="`mr-2 flag-icon flag-icon-${iso2(data.item)}`"
                  ></span>
                  <span class="font-weight-medium">+{{ data.item }}</span>
                  <span v-if="country(data.item)" class="ml-2">{{
                    country(data.item)
                  }}</span>
                </div>
              </template>
              <template slot="item" slot-scope="data">
                <div :style="{ fontSize: '14px' }" class="slot-item">
                  <span
                    v-if="iso2(data.item)"
                    :class="`mr-2 flag-icon flag-icon-${iso2(data.item)}`"
                  ></span>
                  <span class="font-weight-medium">+{{ data.item }}</span>
                  <span v-if="country(data.item)" class="ml-2">{{
                    country(data.item)
                  }}</span>
                </div>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex class="xs6 pl-2">
            <v-text-field
              v-model="model.phone"
              v-validate.disable="phoneNumberRules"
              :error-messages="errors.collect('phone')"
              :data-vv-name="'phone'"
              :data-vv-as="$tc('phone', 2)"
              :label="$tc('phone', 1)"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs6 pl-2">
            <v-text-field
              v-model="model.email_sender_domains"
              :data-vv-name="'email_sender_domains'"
              :label="$tc('profile_page.email_sender_domains', 1)"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="model.password"
              @click:append="show_password = !show_password"
              :type="show_password ? 'text' : 'password'"
              :append-icon="show_password ? 'visibility' : 'visibility_off'"
              v-validate.disable="'min:6'"
              :error-messages="errors.collect('password')"
              :data-vv-name="'password'"
              :data-vv-as="$tc('password', 2)"
              :label="$tc('password', 1)"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card>
    <div class="text-xs-center">
      <v-btn v-if="query.group_id" @click="goBack" round class="text-none">
        {{ $t("back") }}
      </v-btn>
      <v-btn
        round
        class="theme-primary-bg theme-on-primary text-none"
        @click="update"
        :loading="loading"
      >
        {{ $t("save") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { countries } from "@/countries.js";
import "flag-icon-css/css/flag-icon.css";

export default {
  data: () => ({
    loading: false,
    searchInput: null,
    show_password: false,
    model: {
      first_name: "",
      last_name: "",
      email: "",
      phone_code: null,
      phone: null,
      email_sender_domains: "",
    },
  }),
  computed: {
    phoneNumberRules() {
      return process.env.VUE_APP_PHONE_NUMBER_RULES || "numeric|min:8|max:12";
    },
    dialCodes() {
      return [
        { header: this.$t("dialCodeHeader") },
        ...countries.map((el) => el.dialCode),
      ];
    },
    query() {
      return this.$route.query;
    },
  },
  mounted() {
    this.authMe();
  },
  methods: {
    authMe() {
      this.$api.auth.me().then((response) => {
        if (!response) return;
        if (
          response.data &&
          response.data.data &&
          response.data.data.email_sender_domains
        )
          this.model.email_sender_domains =
            response.data.data.email_sender_domains;
        this.model = response.data.data;
      });
    },
    async update() {
      if (this.searchInput) {
        this.model.phone_code = this.searchInput;
      }

      const valid = await this.$validator.validate();

      if (!valid) return;

      this.loading = true;

      const specs = {
        first_name: this.model.first_name,
        last_name: this.model.last_name,
        email: this.model.email,
        phone_code: this.model.phone ? this.model.phone_code : "",
        phone: this.model.phone,
      };

      if (this.model.password) {
        specs.password = this.model.password;
      }

      this.$api.auth.update(specs).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: this.$t("profile_page.updated"),
          });
        },
        (error) => {
          this.loading = false;
          this.errorMessageShow(error);
        },
      );
    },
    iso2(dialCode) {
      if (!dialCode) return;

      const country = countries.find((el) => el.dialCode === Number(dialCode));

      if (!country) return;

      return country.iso2;
    },
    country(dialCode) {
      if (!dialCode) return;

      const country = countries.find((el) => el.dialCode === Number(dialCode));

      if (!country) return;

      return country.name;
    },
    goBack() {
      this.$router.push(
        {
          name: "get_started",
          params: { group_id: this.query.group_id },
        },
        () => {},
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
